import React from "react";

const routes = () => {
    const HomePage = React.lazy(() => import("./pages/HomePage"));
    const DoctorLogin = React.lazy(() => import("./pages/DoctorLogin"));
    const TestRequests = React.lazy(() => import("./pages/test_requests/List"));
    const TestDashboard = React.lazy(() => import("./pages/test_dashboard/List"));
    // const BasicForm = React.lazy(() => import("./pages/test-form/partials/BasicTest"));
    const TestForm = React.lazy(() => import("./pages/test-form/Main"));
    const HealthHistorySearch = React.lazy(() => import("./pages/health_history/Search"));
    const HealthHistory = React.lazy(() => import("./pages/health_history/List"));
    const HealthHistoryView = React.lazy(() => import("./pages/health_history/View"));
    return [
        // { path: "/", exact: true, name: "Home", component: HomePage },
        // { path: "/doctor-login", name: "Doctor Login", component: DoctorLogin },
        { path: "/test-requests", name: "Test Requests", component: TestRequests },
        { path: "/test-dashboard/:id", name: "Test Dashboard", component: TestDashboard },
        { path: '/test-form/:id', name: 'Test Form', component: TestForm },

        { path: '/health-history/search', name: 'Health History Search', component: HealthHistorySearch },
        { path: "/health-history/list", name: "Health History", component: HealthHistory },
        { path: "/health-history/view/:id", name: "Health History View", component: HealthHistoryView },
    ]
}

export default routes;