import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import React from 'react';
import routes from './routes';
import { CFade } from '@coreui/react';
import bg1 from './images/background_4.jpg';
import './scss/style.scss';
import { getIsLoggedIn } from './utils/auth';

function App() {
    const style = {
        backgroundImage: ` url(${bg1})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        overflow: 'hidden',
        height: "100vh",
        overflowY: 'auto',
        overflowY: 'overlay',
    }
    console.log(getIsLoggedIn(), 'getIsLoggedIn');
    const HomePage = React.lazy(() => import("./pages/HomePage"));
    const DoctorLogin = React.lazy(() => import("./pages/DoctorLogin"));

    return (

        <div style={style}>
            <BrowserRouter>
                <React.Suspense>
                    <Switch>
                        <Route exact path="/" component={HomePage} />
                        <Route path="/doctor-login" component={DoctorLogin} />
                        {
                            routes().map((route, index) => {
                                return route.component && (
                                    <Route key={index} path={route.path} exact={route.exact} name={route.name} render={props => (
                                        getIsLoggedIn() ?
                                            <CFade>
                                                <route.component {...props} />
                                            </CFade>
                                            : <Redirect to="/" />
                                    )} />
                                )
                            })
                        }
                        <Redirect from='*' to='/' />
                    </Switch>
                </React.Suspense>
            </BrowserRouter>
        </div>
    );
}

export default App;
