export const saveIsLoggedIn = () => {
    localStorage.setItem('isLoggedIn', true);
}

export const getIsLoggedIn = () => {
    return localStorage.getItem('isLoggedIn') ? true : false;
}

export const removeISLoggedIn = () => {
    localStorage.removeItem('isLoggedIn');
}

export const saveOperatorNo = (operatorNo) => {
    localStorage.setItem('operatorNo', operatorNo);
}

export const getOperatorNo = () => {
    return localStorage.getItem('operatorNo') ? localStorage.getItem('operatorNo') : null;
}

export const removeOperatorNo = () => {
    localStorage.removeItem('operatorNo');
}

export const saveConsultantId = (consultantId) => {
    localStorage.setItem('consultantId', consultantId);
}

export const getConsultantId = () => {
    return localStorage.getItem('consultantId') ? localStorage.getItem('consultantId') : null;
}

export const removeConsultantId = () => {
    localStorage.removeItem('consultantId');
}