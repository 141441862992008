import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App.js";
import { icons } from "./images/index.js";
React.icons = icons
ReactDOM.render(
  <Suspense fallback={<div>Loading... </div>}>
    <App />
  </Suspense>,
  document.getElementById("root")
);